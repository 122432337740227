import * as React from 'react'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import PageTemplate from '../templates/page'
import BrandTemplate from '../templates/brand'
import Layout from '../components/Layout'

import { HeroSubTitle } from '../styles/base'

import tw from 'twin.macro' /* eslint-disable-line */

const NotFoundPage = () => ( 

<Layout>
    <div tw='bg-gray-200 pt-8 px-7'>
      <HeroSubTitle tw='max-w-3xl  mt-5 text-center mx-auto px-7 md:px-5 pb-16 font-gibson min-h-screen'>
        404: Page Not Found
      </HeroSubTitle>
    </div>
</Layout>




 )

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: PageTemplate,
    brand: BrandTemplate,
  },
})
